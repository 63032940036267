// FONT VARIABLES
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

$font-display: 'Poppins', sans-serif;

// FUENTE METROPOLIC
@font-face {
  font-family: 'FF Cocon';
  src: url('./Fonts/Cocon-Regular-Font.otf');
  font-style: normal;
  font-weight: 700;
}

// FUENTE METROPOLIC
@font-face {
  font-family: 'Metropolis';
  src: url('./Fonts/Metropolis-Bold.otf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Metropolis';
  src: url('./Fonts/Metropolis-Regular.otf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Metropolis';
  src: url('./Fonts/Metropolis-Light.otf');
  font-style: normal;
  font-weight: 300;
}

// FUENTE OPTIMA NOVA
@font-face {
  font-family: 'Optima Nova';
  src: url('./Fonts/OptimaNovaLTProBold.otf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Optima Nova';
  src: url('./Fonts/OptimaNovaLTProDemi.otf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Optima Nova';
  src: url('./Fonts/OptimaNovaLTProRegular.otf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Optima Nova';
  src: url('./Fonts/OptimaNovaLTProLight.otf');
  font-style: normal;
  font-weight: 300;
}

:root {
  --color-primary: #0053a2;
  --color-primary-light: #1967af;
  --color-primary-dark: #014f97;

  --color-secondary: #007fc3;
  --color-secondary-light: #007fc3;
  --color-secondary-dark: #007fc3;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #e2e1e1;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  // --color-grey-product: #000;
  --color-grey-product: #6b6b6b;

  --color-red: #ff3333;
  --color-red-dark: #ec3535;
  --color-red-light: #ff4040;

  --color-success: #41d888;
  --color-success-dark: #38c479;

  --color-warning: #ebb734;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);

  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

*,
*::before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

html {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 50px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-light);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  box-sizing: border-box;
  font-size: 62.5%; /* 1 rem = 10px - 62.5%*/

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }
}

body {
  font-family: $font-display;
  -webkit-text-size-adjust: none;
}

input,
textarea,
button,
select,
svg,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  height: 50vh;
}
