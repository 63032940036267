.container {
  position: relative;
}

.container .card {
  position: relative;
  width: 100%;
  height: 450px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.container .card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: var(--color-primary);

  background: rgb(0, 83, 162);
  background: linear-gradient(
    90deg,
    rgba(0, 83, 162, 1) 0%,
    rgba(27, 116, 198, 1) 59%,
    rgba(80, 171, 255, 1) 100%
  );
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
  z-index: 2;
}

.container .card:hover:before {
  clip-path: circle(300px at 80% -20%);
}

.container .card:after {
  content: 'Agen';
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 19rem;
  font-weight: 800;
  font-style: italic;
  color: rgba(0, 0, 0, 0.05);
}

.container .card .imgBx {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 100%;
  height: 220px;
  transition: 1s;
}

.container .card:hover .imgBx img {
  width: 170px;
  transform: translate(-50%, -80%);

  @media only screen and (max-width: 1100px) {
    width: 185px;
    transform: translate(-50%, -78%);
  }
}

.container .card .imgBx img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -68%);
  width: 200px;
  transition: 1s;

  @media only screen and (max-width: 1100px) {
    top: 58%;
    width: 215px;
  }
}

.container .card .contentBx {
  position: absolute;
  bottom: 5%;
  width: 100%;
  height: max-content;
  text-align: center;
  transition: 1s;
  z-index: 2;

  display: grid;
  // grid-gap: 0.5rem;
  justify-content: center;
  justify-items: center;

  @media only screen and (max-width: 1100px) {
    bottom: 4%;
  }
}

.container .card:hover .contentBx {
  bottom: 16%;

  @media only screen and (max-width: 1100px) {
    bottom: 14%;
  }
}

.container .card .contentBx h1 {
  position: relative;
  font-size: 2.3rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #000;

  &.price {
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
}

.container .card .contentBx a {
  position: absolute;
  bottom: -55%;
  display: inline-block;
  height: max-content;
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #000;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #000;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;

  @media only screen and (max-width: 1100px) {
    bottom: -62%;
  }
}

.container .card:hover .contentBx a {
  bottom: -55%;
  opacity: 1;
  font-size: 1.6rem;
  transform: translateY(0);
  transition-delay: 0.5s;

  @media only screen and (max-width: 1100px) {
    bottom: -62%;
  }
}
